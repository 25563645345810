
/* testo tipo h1 */
h1 {
  font-size: 1.8em;
  margin: 0.67em 0;
  font-family:roboto;
  text-align: center;
  color: #f5f5f5;
}

h2 {
  font-size: 1.4em;
  margin: 0.67em 0;
  font-family:roboto;
  text-align: center;
  color: #f5f5f5;
}

.geo-container {
    display: flex; /* Use flexbox layout */
    flex-direction: row; /* Default to row direction */
    align-items: center; /* Center align items vertically */
    justify-content: space-between; /* Space items evenly */
    margin: 0 ; /* Remove margin to utilize full width */
    padding: 20px 80px; /* Add 20px padding to top/bottom and 60px to left/right */
    border: 2px solid #12455b; /* Border color and thickness */
    background-color: #ffffff; /* Background color for the container */
    width: 100vw; /* Set width to 100% of viewport width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.geo-container-home {
    display: flex; /* Use flexbox layout */
    flex-direction: row; /* Default to row direction */
    align-items: center; /* Center align items vertically */
    justify-content: space-between; /* Space items evenly */
    margin: 0 ; /* Remove margin to utilize full width */
     padding: 20px 80px 70px 80px; /* Top | Right | Bottom | Left */
    border: 2px solid #12455b; /* Border color and thickness */
    background-color: #ffffff; /* Background color for the container */
    width: 100vw; /* Set width to 100% of viewport width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.image-carousel {
    display: flex; /* Use flexbox to align items horizontally */
    flex-grow: 0; /* Allow this section to not grow */
    align-items: center; /* Center items vertically */
    position: relative; /* Set relative position for inner elements */
    max-width: 400px; /* Set a max width for the image carousel */
    width: 100%; /* Make the carousel take full width */
}

.carousel-image {
    max-width: 100%; /* Responsive width */
    max-height: 100%; /* Responsive height */
    border: 1px solid black; /* Optional: add border to the image */
    border-radius: 4px; /* Optional: round corners */
}

.arrow {
    position: absolute; /* Position arrows absolutely */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust to center */
    background-color: rgba(255, 255, 255, 0.7); /* Slightly transparent background */
    border: none; /* No border */
    font-size: 24px; /* Arrow size */
    cursor: pointer; /* Change cursor to pointer */
    z-index: 10; /* Ensure arrows are on top */
    padding: 10px; /* Padding around the arrow */
    border-radius: 50%; /* Round arrows */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.arrow:hover {
    background-color: #f5f5f5; /* Change background color on hover */
    transform: translateY(-50%) scale(1.1); /* Slightly increase size on hover */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.arrow.left {
    left: 10px; /* Position left arrow */
}

.arrow.right {
    right: 10px; /* Position right arrow */
}

.im-i2 {
    flex-grow: 1; /* Allow this section to grow */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    text-align: center; /* Center text */
    padding-left: 20px; /* Optional: Add some left padding */
}

.title-im {
    font-size: 1.5em; /* Adjust title size */
    font-weight: bold; /* Bold title */
    margin-bottom: 10px; /* Spacing below title */
    color: #000000; /* Set text color to black */
}

.text-im {
    font-size: 0.9em; /* Adjust text size */
    color: #000000; /* Text color */
}

.descriptions {
    text-align: left; /* Aligns text to the left */
    margin: 0; /* Remove any default margin */
    padding: 60px 120px 80px 120px; /* Optional: add some padding */
    color: #f5f5f5; /* Text color */
}

.title-desc {
    font-size: 1.5em; /* Adjust title size */
    font-weight: bold; /* Bold title */
    margin-bottom: 10px; /* Spacing below title */
    color: #f5f5f5; /* Set text color to black */
}

.text-desc {
    font-size: 0.9em; /* Adjust text size */
    color: #f5f5f5; /* Text color */
}

.title-desc-home {
    font-size: 1.2em; /* Adjust title size */
    font-weight: bold; /* Bold title */
    margin: 0px; /* Spacing below title */
    color: #f5f5f5; /* Set text color to black */
}

.descriptions-home {
    text-align: left; /* Aligns text to the left */
    margin: 0; /* Remove any default margin */
    padding: 15px; /* Optional: add some padding */
    color: #f5f5f5; /* Text color */
}


/* Container Download and preview*/
.tools {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px; /* Adjust padding to your needs */
    max-width: 400px; /* Optional: set a max width */
    margin: 0 auto; /* Center container horizontally */
}


/* Pulsante download */
.navigate-button {
    display: flex; /* Flex display to match .im-i2 */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    padding: 10px 20px; /* Padding for the button */
    background-color: #048d36; /* Black background color */
    color: white; /* Text color */
    border: 3px solid #000000; /* No border */
    border-radius: 8px; /* Rounded corners to match .im-i2 */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background change */
}

.navigate-button:hover {
    background-color: #03b142; /* Dark green on hover */
    color: #f5f5f5; /* Change text color to light green on hover */
}

/* Blog preview */
.blog-preview {
    text-align: center; /* Center text in preview */
    margin-bottom: 20px; /* Space between preview and button */
    display: flex;
    flex-direction: column;
    padding: 4px 8px; /* Add padding inside the border */
    border: 2px solid #12455b; /* Border color and thickness */
    border-radius: 6px; /* Rounded corners */
    background-color: #f5f5f5; /* Change color on hover */
    align-items: center;
}

.preview-image {
    max-width: 60%; /* Make image responsive */
    height: auto;
    border-radius: 8px; /* Rounded corners */
    margin-bottom: 15px; /* Space between image and text */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
}

.preview-title {
    font-size: 1.0em; /* Adjust title size */
    font-weight: bold;
    color: #000000; /* Custom color */
}

.preview-text {
    font-size: 0.8em;
    color: #000000;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
    padding: 4px 8px; /* Add padding inside the border */
    border: 2px solid #000000; /* Border color and thickness */
    border-radius: 6px; /* Rounded corners */
}

.preview-text:hover {
    color: #f5f5f5; /* Change color on hover */
    background-color:#12455b;
}

.image-description {
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
    color: #000000; /* Adjust as needed */
    font-weight: bold;
}

/* App.css */
.clickable-text {
    color: #E9A628;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.5em;
    transition: color 0.3s ease; /* Smooth color transition on hover */
}

.clickable-text:hover {
    color: #efc068; /* Change to a darker red or any color you want on hover */
}



.image-blog-description.clickable {
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
    color: #000000; /* Adjust as needed */
    font-weight: bold;
}

.image-blog-description.clickable:hover {
    color: #E9A628; /* Change color on hover */
    border: 2px solid #12455b; /* Add border on hover */
    border-radius: 4px; /* Optional: rounded corners */
    margin: 4px;
}


.logo-container {
    text-align: center; /* Center the logo */
    margin-top: 25px; /* Space below the logo */
    margin-bottom: 5px; /* Space below the logo */
}



.logo {
    width: 110px; /* Adjust based on your design */
    height: auto; /* Maintain aspect ratio */
}

/* Media query to adjust layout for smaller screens */
@media screen and (max-width: 768px) {
    .geo-container {
        flex-direction: column; /* Stack children vertically */
        align-items: center; /* Center children horizontally */
    }

    .image-carousel {
        margin-right: 0; /* Remove right margin */
        margin-bottom: 20px; /* Space below the carousel */
    }

    .im-i2 {
        padding-left: 0; /* Remove left padding */
        align-items: center; /* Center the text */
        text-align: center; /* Center text */
    }
}