/* non usato lo stile del bottone in quanto se aggiungo la classe non scarica i dati */

/* Style buttons */
.btndw {
  background-color: white;
  border:2px solid #12455b;
  border-radius: 12px;
  color: black;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 11px;
}

/* Darker background on mouse-over */
.btndw:hover {
  background-color: #68ecba;
}

.br {
  margin-top:20px;
}

.map1 {
    /* Adjust this based on the height of your header */
    height: 100vh;
}

/* Loading Indicator Styles */
.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    z-index: 3000; /* Ensure it appears above other content */
    text-align: center;
}

/* Spinner Styles */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
    border-left: 4px solid #000; /* Black */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Center the spinner horizontally */
}

/* Spinner Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Spinner Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#legendContainer {
   position: absolute;
    top: 78%;
    left: 30%;
    z-index: 3000;
}

