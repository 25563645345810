
.geo-container-art {
    display: flex; /* Use flexbox layout */
    flex-direction: row; /* Default to row direction */
    align-items: center; /* Center align items vertically */
    justify-content: space-between; /* Space items evenly */
    margin: 0 ; /* Remove margin to utilize full width */
    padding: 20px 80px; /* Add 20px padding to top/bottom and 60px to left/right */
    border: 0px ; /* Border color and thickness */
    background-color: #ffffff; /* Background color for the container */
    width: 100vw; /* Set width to 100% of viewport width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.text-im-art {
    font-size: 1em; /* Standard text size for readability */
    color: #000000; /* Text color */
    text-align: justify;
    padding: 20px 60px; /* 20px padding top/bottom, 60px left/right */
    line-height: 1.6; /* Adjust line-height for better readability */
    max-width: 800px; /* Limit content width for easier line reading */
    margin: 0 auto; /* Center-align content */
}

