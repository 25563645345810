

.leaflet-ruler {
  height: 35px;
  width: 35px;
  background-image: url('./static/Icons/ruler.png');
  background-repeat: no-repeat;
  background-position: center;
}
.leaflet-ruler:hover {
  /*background-color:#68ecba;*/
  border-color: #12455b !important;
  /*background-image: url("../dist/icon-colored.png"); /* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */
}
.leaflet-ruler-clicked {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./static/Icons/ruler.png');
  background-size: 1;
  background-color:#68ecba !important;
  border-color: #12455b !important;
}
.leaflet-bar {
  background-color: rgba(255, 255, 255, 0.7);
}
.leaflet-control {
  cursor: pointer;
}
.result-tooltip {
  background-color: white;
  border-width: medium;
  border-color: #12455b;
  font-size: smaller;
}
.moving-tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  opacity: 0.5;
  border: dotted;
  border-color: #12455b;
  font-size: smaller;
}
.plus-length {
  padding-left: 45px;
}
