/* Base Styles */
.Principal-login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    background-color: #f5f5f5;
    padding: 10px;
}

.login-block {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 100%;
    text-align: center;
}

form label {
    font-size: 1rem;
    color: #000000;
     text-align: center;
    display: block;
    margin-bottom: 5px;
}

form input[type="text"],
form input[type="password"],
form input[type="email"],
form textarea{
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    box-sizing: border-box; /* Ensures consistent sizing */
}

.btn-block {
    width: 100%;
    padding: 12px;
    background-color: #12455b;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 15px;
    transition: background-color 0.3s ease;
    text-align: center;
}

.btn-block:hover {
    background-color: #6BA2B9;
}

/* Additional Options Styles */
.additional-options {
    margin-top: 20px;
}

.additional-options p {
    font-size: 0.9em;
    color: #555;
    margin: 5px 0;
}

.additional-options a {
    color: #007bff;
    text-decoration: none;
    font-size: 0.9em;
}

.additional-options a:hover {
    text-decoration: underline;
}

.password-input-container {
    position: relative;
    width: 100%;
}

.password-input-container input {
    width: 100%;
    padding-right: 40px; /* Space for the toggle button */
    text-align: center; /* Center text and placeholder */
}


.toggle-password-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #007bff;
    outline: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .Principal-login {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 120vh; /* Full viewport height to center vertically */
        background-color: #f5f5f5;
        padding: 0;
    }

    .login-block {
        padding: 5px 5px 5px 5px; /* Top Right Bottom Left */
        max-width: 80%; /* Ensures block doesn't stretch too wide */
        box-sizing: border-box;
        margin-bottom: 0;
    }

    form label,
    form input[type="text"],
    form input[type="password"],
    form input[type="email"],
    form textarea,
    .btn-block,
    .additional-options p,
    .additional-options a {
        font-size: 0.8rem; /* Slightly larger for better readability */
        padding: 2px;
    }
}


@media (max-width: 480px) {
    .login-block {
        padding: 20px;
    }

    form label {
        font-size: 0.8rem;
    }

    form input[type="text"],
    form input[type="password"],
    form input[type="email"],form textarea,{
        padding: 10px;
        font-size: 0.7rem;
    }

    .btn-block {
        padding: 10px;
        font-size: 0.9rem;
    }

    .additional-options p,
    .additional-options a {
        font-size: 0.85rem;
    }
}

