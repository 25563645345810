/* generale*/
.Principal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full container width */
  height: calc(100vh - 150px); /* Minimum height to fit the viewport */
  padding: 10px; /* Padding around content */
  margin-top: 15px; /* Push content below navbar */
  margin-bottom: 80px; /* Prevent content from overlapping with footer */
  box-sizing: border-box;
  overflow: hidden;
}

/* titolo*/
.Title-sh {
  font-size: 1.0em; /* Adjust size as needed */
    font-weight: bold; /* Make the text bold */
    color: #E9A628; /* Change to your preferred color */
    text-align: center; /* Center align the text */
    margin: 5px 0; /* Optional: add some vertical spacing */
    font-family: 'Roboto', sans-serif; /* Ensure a consistent font */
}

.divider {
    width: 80%; /* Full width */
    height: 2px; /* Height of the line */
    background-color: #E9A628; /* Change this to your desired color for the divider */
    margin: 0px 10; /* Optional: space above and below the line */
}

.layoutapp {
    display: flex;
    width: 100%;
    height: 100vh;
    border: 4px solid #E9A628; /* Add border */
    border-radius: 6px; /* Optional: add border radius if needed */
    box-sizing: border-box;
    overflow: hidden;
    z-index: 1000;
}


.infotab {
    background-color: #F1F1F1;
    z-index: 500;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: auto; /* Disable vertical scrolling */
    position: relative; /* Position context for the handle */
    padding-right: 15px; /* Ensure padding to avoid content hiding behind the handle */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.resize-handle {
    position: absolute; /* Position it within the wrapper */
    right: 1; /* Align with the right edge of the wrapper */
    top: 0; /* Align with the top of the wrapper */
    width: 5px; /* Width of the resize handle */
    cursor: ew-resize;
    height: 100%;
    background-color: #ccc;
    z-index: 1000; /* Ensure it is above other content */
}

.App {
    flex: 7.5; /* 75% width */
    overflow: hidden;
}

.menu {
    margin-bottom: 20px; /* Space between menu and content */
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Align items horizontally */
    gap: 15px; /* Space between menu items */
}

.menu li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent; /* Highlight active item */
}

.menu li.active {
    font-weight: bold;
    border-bottom: 2px solid #E9A628; /* Highlight active item */
}



.piecharts-container {
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Stack charts vertically */
    align-items: center; /* Center items horizontally */
    gap: 0px; /* Space between charts */
    padding: 5px; /* Optional padding around the container */
}


.piechart1, .piechart2 {
    width: 90%; /* Full width of the parent container */
    max-width: 300px; /* Maximum width to prevent excessive growth */
    height: auto; /* Auto height based on aspect ratio */
    max-height: 300px; /* Maximum height for better visibility */
    margin: 0px 0; /* Space between charts */
    box-sizing: border-box; /* Include padding and border in total width and height */
}




/* tabella layer testi */
  th {
    font-size: 15px;
    text-align: left;
    color:black;
    font-family:roboto;
  }

  td {
    font-size: 13px;
    text-align: left;
    vertical-align: middle;
    font-family:roboto;
    color:black;
  }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.layer-categories {
    display: flex;
    flex-direction: column;
}

.category {
    margin-bottom: 1em;
}

.inline-filters {
    margin-left: 20px;
    flex-direction: column;
}


.filter-image {
    vertical-align: middle;
    margin-right: 5px; /* Space between image and text */
}

.container-button {
            display: flex;
            justify-content: center;
            align-items: center;
             /* Full viewport height */
        }

.button-pane {
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  border: 2px solid #12455b;
  color: black;
  width: 160px;
  height: 30px;
  cursor: pointer;
  border-radius: 12px;
  background-color: white;
  margin-top: 3px;
  transition: background-color 0.3s, color 0.3s;
}

.button-pane:hover {
  background-color: #12455b;
  color: white;
}

/* Parent container for panes */
.container-tb {
  position: relative; /* Establishes the positioning context for child elements */
  width: 100%; /* Full width of its container */
  height: 100vh; /* Full viewport height, or adjust based on your needs */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  white-space: nowrap; /* Prevent wrapping of the panes */
}

/* Ensure the panes are displayed inline horizontally */
.pane1-tb, .pane2-tb, .pane3-tb {
  display: inline-block; /* Make panes align horizontally */
  width: 220px; /* Ensure consistency */
  height: 420px; /* Ensure consistency */
  vertical-align: top; /* Align panes to the top */
  z-index: 600;
  /* Optional: Add margin to panes if needed */
  margin-right: 10px; /* Add space between panes */
}



