
/* generale*/
.Principal3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




.layoutapp3 {
    display: flex;
    width: 90%;
    height: calc(100vh - 30vh);
    border: 4px solid #E9A628; /* Add border */
    border-radius: 6px; /* Optional: add border radius if needed */
    z-index: 1000;
}

.App3 {
    flex: 10; /* 75% width */
    overflow: hidden;
}












