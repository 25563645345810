.footer-bs{
  margin-top: 1rem;
  padding: 1rem;
  background-color: #000000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 1000;
}

@media screen and (max-width: 390px) {
.footer-bs{
  margin-top: 1rem;
  padding: 1rem;
  background-color:  #333;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3%;
  z-index: 1000;
}
}

@media screen and (max-height: 575.98px) and (orientation: landscape) {
.footer-bs{
  margin-top: 1rem;
  padding: 1rem;
  background-color: #333;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3%;
  z-index: 1000;
}
}


.text-int{
  font-size: 14px;
  font-family:roboto;
  text-align: left;
  color: #f9f9f9;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

@media screen and (max-width: 390px) {
.text-int{
  font-size: 8px;
  font-family:roboto;
  text-align: left;
  color: #f9f9f9;
  position: absolute;
  top: 85%;
  left: 5%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
}

@media screen and (max-height: 575.98px) and (orientation: landscape){
.text-int{
  font-size: 6px;
  font-family:roboto;
  text-align: left;
  color: #f9f9f9;
  position: absolute;
  top: 50%;
  left: 3%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
}


.ref-social{
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
    left: 48%;
    color:#f5f5f5;
}

@media screen and (max-width: 390px) {
.ref-social{
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
    left: 5%;
    color:#f5f5f5;
}
}

@media screen and (max-height: 575.98px) and (orientation: landscape) {
.ref-social{
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
    left: 50%;
    color:#f5f5f5;
}
}

.fa_custom {
color: #f5f5f5;
}


/* footer*/
.footer {
  position: absolute;
  top: 20%;
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
    width: 2.5%;
    height: 2.5%;
    right: 4%;
    z-index: 500;
 }


 @media  screen and (max-width: 1680px) {
   .footer {
  position: absolute;
  top: 23%;
  -ms-transform: translateY(-23%);
  transform: translateY(-23%);
    width: 2.5%;
    height: 2.5%;

    right: 5%;
    z-index: 500;
 }
}


 @media screen and (max-width: 1440px) {
   .footer {
  position: absolute;
  top: 24%;
  -ms-transform: translateY(-24%);
  transform: translateY(-24%);
    width: 3%;
    height: 3%;

    right: 5%;
    z-index: 500;
 }
}

 @media screen and (max-width: 1366px) {
   .footer {
  position: absolute;
  top: 26%;
  -ms-transform: translateY(-26%);
  transform: translateY(-26%);
    width: 3%;
    height: 3%;

    right: 5%;
    z-index: 500;
 }
}


@media  screen and (max-width: 1280px) {
   .footer {
  position: absolute;
  top: 26%;
  -ms-transform: translateY(-26%);
  transform: translateY(-26%);
    width: 3%;
    height: 3%;

    right: 5%;
    z-index: 500;
 }
}


@media screen and (max-width: 1025px) {
   .footer {
  position: absolute;
  top: 32%;
  -ms-transform: translateY(-32%);
  transform: translateY(-32%);
    width: 3%;
    height: 3%;

    right: 7%;
    z-index: 500;
 }
}

@media screen and (max-width: 390px) {
   .footer {
  position: absolute;
  top: 32%;
  -ms-transform: translateY(-32%);
  transform: translateY(-32%);
    width: 4%;
    height: 4%;

    right: 12%;
    z-index: 500;
 }
}

@media screen and (max-height: 575.98px) and (orientation: landscape) {
   .footer {
  position: absolute;
  top:30%;
  -ms-transform: translateY(-32%);
  transform: translateY(-32%);
    width: 3%;
    height: 3%;
    right: 12%;
    z-index: 500;
 }
}



