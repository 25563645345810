/* Navbar container */
.navbar {
  overflow: hidden;
  background-color: #000000;
  font-family: Arial;
  z-index: 2000;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 16px;
  color: #f5f5f5;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}

@media screen and (max-width: 390px)  {
.navbar a {
  float: left;
  font-size: 12px;
  color: #f5f5f5;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}
}

@media screen and (max-height: 575.98px) and (orientation: landscape) {
.navbar a {
  float: left;
  font-size: 12px;
  color: #f5f5f5;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}


/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color:#f5f5f5;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}

@media screen and (max-width: 390px) {
.dropdown .dropbtn {
  font-size: 12px;
  border: none;
  outline: none;
  color: #f5f5f5;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}
}

@media screen and (max-height: 575.98px) and (orientation: landscape) {
.dropdown .dropbtn {
  font-size: 12px;
  border: none;
  outline: none;
  color: #f5f5f5;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;
}
}



.navbar a:hover {
    border-color: #f5f5f5; /* Change border color on hover */
    z-index: 2000; /* Maintain z-index if needed */
}

/* Optional: Style for dropdown button hover */
.dropdown:hover .dropbtn {
    border-color: #f5f5f5; /* Change border color for dropdown button on hover */
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f5f5f5;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2000;
  border: 2px solid transparent; /* Transparent border to maintain layout */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  border-radius: 8px;

}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  z-index: 2000;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  border-color: #000000;
  z-index: 2000;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  z-index: 2000;
}


