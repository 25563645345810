
/* non usato lo stile del bottone in quanto se aggiungo la classe non scarica i dati */
.map3 {
    /* Adjust this based on the height of your header */
    height: calc(100vh - 30vh);
}

.layertab_newdata {
  background-color: #90e0fc99;
  position: absolute;
    top: 0%;
    right: -33%;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: #12455b;
    z-index: 500;
    width: 25%;
 }

.form_data{text-align: center;
}

input[type=text]:focus,input[type=url]:focus, select:focus, textarea:focus {
  background-color: lightblue;
}

input[type=text],input[type=url], select, textarea {
  text-align: center;
  width: 150px; /* Full width */
  padding: 8px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}


/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #D0E0E3;
  color: black;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}


.attachment{

  background-color: #D0E0E3;
  color: black;
  padding: 9px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.attachment:hover {
  background-color: #45a049;
}

